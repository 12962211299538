<template>
  <div class="layout">
    <el-container>
      <el-aside width="180px">
        <app-aside />
      </el-aside>
      <el-main>
        <el-header>
          <app-header />
        </el-header>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import AppAside from './components/AppAside'
import AppHeader from './components/AppHeader'

export default {
  name: 'Layout',
  components: {
    AppAside,
    AppHeader
  }
}
</script>

<style lang="scss" scoped>
.el-container {
  height: 100vh;
  min-width: 980px;
  .el-main {
    padding-top: 0;
    .el-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      .el-breadcrumb {
        font-size: 16px;
        line-height: 1;
        color: #073050;
      }
      .el-dropdown {
        padding-left: 2px;
        padding-right: 10px;
        width: 127px;
        height: 36px;
        box-shadow: 0px 4px 20px 0px rgb(87 83 181 / 10%);
        border-radius: 22.5px;
      }
      .el-dropdown-link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 36px;
        cursor: pointer;
        .avatar {
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
            width: 32px;
            height: 32px;
            border-radius: 32px;
          }
        }
        i {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
