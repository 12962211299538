<template>
  <div class="app-aside">
    <el-menu
      :default-active="defaultActive"
      class="el-menu-vertical-demo"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      router
    >
      <el-submenu index="1">
        <template slot="title">
          <i class="el-icon-document-copy"></i>
          <span>内容管理</span>
        </template>
        <el-menu-item index="/category">
          <i class="el-icon-tickets" />
          栏目管理
        </el-menu-item>
        <el-menu-item index="/article">
          <i class="el-icon-reading" />
          文章管理
        </el-menu-item>
      </el-submenu>
      <el-menu-item
        v-for="(menu, index) in menuArr"
        :key="index"
        :index="menu.path">
        <i :class="menu.icon"></i>
        <span slot="title">{{ menu.title }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'AppAside',
  data () {
    return {
      menuArr: [
        { title: '月嫂管理', path: '/babysitter', icon: 'el-icon-user-solid' },
        { title: '商品管理', path: '/product', icon: 'el-icon-s-goods' },
        { title: '预约管理', path: '/appointment', icon: 'el-icon-document' },
        { title: '用户管理', path: '/user', icon: 'el-icon-s-custom' },
        { title: '常见问题', path: '/question', icon: 'el-icon-chat-line-round' },
        { title: '系统管理', path: '/setting', icon: 'el-icon-s-tools' }
      ]
    }
  },
  computed: {
    currentPath () {
      return this.$route.fullPath
    }
  },
  created () {
    this.defaultActive = this.$route.fullPath
  }
}
</script>

<style lang="scss" scoped>
.app-aside {
  height: 100%;
  .el-menu {
    height: 100%;
    border-right: 0 none;
    overflow-x: hidden;
  }
}
</style>
