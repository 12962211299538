<template>
  <div class="app-header">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        首页
      </el-breadcrumb-item>
      <el-breadcrumb-item
        v-for="(breadcrumb, index) in breadcrumbArr"
        :key="index"
      >
        {{ breadcrumb.meta.title }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-dropdown trigger="click">
      <span class="el-dropdown-link">
        <el-avatar
          :size="30"
          :src="userInfo.avatar"
        />
        <p class="username">{{ userInfo.name || '管理员' }}</p>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>用户信息</el-dropdown-item>
        <el-dropdown-item
          divided
          @click.native="handleLogout"
        >退出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { getCurrentUserInfo } from '@/services/user'
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  name: 'AppHeader',
  data () {
    return {
      userInfo: {},
      breadcrumbArr: []
    }
  },
  watch: {
    '$route.path': {
      handler () {
        this.getBreadcrumb()
      },
      immediate: true
    }
  },
  computed: {
    ...mapState(['user'])
  },
  created () {
    this.getCurrentUserInfo()
  },
  methods: {
    async getCurrentUserInfo () {
      const { data } = await getCurrentUserInfo()
      this.userInfo = data
    },
    handleLogout () {
      this.$confirm('确认退出吗?', '退出提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // 单独初始化 axios
        const request = axios.create({
          baseURL: '/api/v1'
        })
        try {
          await request({
            method: 'DELETE',
            url: '/authorizations/current',
            headers: {
              Authorization: 'Bearer ' + this.user.access_token
            }
          })
          // 成功请求接口黑名单当前 token 并清空信息跳转
          this.removeCurrentUserInfo()
        } catch (error) {
          // token 过期请求失败清空信息跳转
          this.removeCurrentUserInfo()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消退出'
        })
      })
    },
    removeCurrentUserInfo () {
      this.$store.commit('setUser', null)
      this.$router.push('/login')
      this.$message({
        type: 'success',
        message: '退出成功!'
      })
    },
    getBreadcrumb () {
      this.breadcrumbArr = this.$route.matched.filter(item => item.meta && item.meta.title)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  .el-dropdown {
    .username {
      margin: 0 5px;
      cursor: pointer;
    }
    .el-dropdown-link {
      display: flex;
      align-items: center;
    }
  }
}
</style>
